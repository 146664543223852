.vehicule-container {
  min-width: 52px;
}

.vehicule {
  height: 24px;
}

.AP {
  width: 52px;
  background-image: url(../tucs_sprite_AP.png);
}
.SDIS {
  width: 52px;
  background-image: url(../tucs_sprite_SDIS.png);
}
.BC {
  width: 38px;
  background-image: url(../tucs_sprite_BC.png);
}
.HELI {
  width: 52px;
  background-image: url(../tucs_sprite_HELI.png);
}
.AUTRE,
.NP {
  width: 24px;
  background-image: url(../tucs_sprite_AUTRE_NP.png);
}
.AMB,
.UMH {
  width: 52px;
  background-image: url(../tucs_sprite_AMB_UMH.png);
}
.AP_ET_SMUR {
  width: 52px;
  background-image: url(../tucs_sprite_AP_SMUR.png);
}
.SDIS_ET_SMUR {
  width: 52px;
  background-image: url(../tucs_sprite_SDIS_SMUR.png);
}
.VL,
.VL_perso,
.MG {
  width: 36px;
  background-image: url(../tucs_sprite_VL_VLPerso_MG.png);
}
.FO {
  width: 52px;
  background-image: url(../tucs_sprite_FO.png);
}
.icon {
  height: 24px;
  width: 24px;
}
